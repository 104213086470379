<template>
  <div class="about">
    <div class="head" v-if="screenWidth>975">
      <div class="logobox" @click.native="toHome">
        <img src="../../assets/images/logo.png" alt />
      </div>
      <div class="navbox">
        <div class="navitem" @click="toHome">首页</div>
        <div class="navitem" @click="toAbout">关于我们</div>
        <div class="navitem navitem-on" @click="toVideo">视频中心</div>
        <div class="navitem" @click="toPlatform">开放平台</div>
      </div>
    </div>
    <div class="p-head" v-else>
      <header>
        <div class="p-head">
          <div class="p-head-logo">
            <img src="../../assets/images/logo.png" alt />
          </div>
        </div>
        <div class="p-head-home">
          <Dropdown trigger="click" style="margin-left: 20px" placement="bottom-end">
            <a href="javascript:void(0)">
              <Icon type="md-menu" size="22" />
            </a>
            <DropdownMenu slot="list">
              <DropdownItem @click.native="toHome">首页</DropdownItem>
              <DropdownItem @click.native="toAbout">关于我们</DropdownItem>
              <DropdownItem @click.native="toVideo">视频中心</DropdownItem>
              <DropdownItem @click.native="toPlatform">开放平台</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </header>
    </div>
    <div class="main">
      <div class="watermark"><img src="../../assets/images/watermark.png" alt=""></div>
      <!-- <div class="video-title">{{videoTitle}}</div> -->
      <div class="videoBox">
        <div class="open-btn" v-if="!descFlag && screenWidth>760" @click="changeIntroduction"><img src="../../assets/images/close_right.png" alt=""></div>
        <video-player
          ref="videoPlayer"
          class="video-player vjs-custom-skin"
          :playsinline="true"
          :options="playerOptions"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
          :style="{'margin-right': ( descFlag && screenWidth>760 ? '20%':'0')}"
        />
        <div class="vi-box" v-show="descFlag && screenWidth>760">
          <div class="video-introduction">
            <div class="vi-title">{{videoTitle}}</div>
            <div class="small-title">内容介绍</div>
            <div class="vi-desc">{{videoDesc}}</div>
            <!-- <div class="close-btn"><img src="../../assets/images/close_left.png" alt=""></div> -->
            <div class="close-btn" @click="changeIntroduction"><img src="../../assets/images/close_right.png" alt=""></div>
          </div>
        </div>
      </div>
      <div class="p-introduction" v-if="screenWidth<=760">
        <div class="p-title">{{videoTitle}}</div>
        <div class="p-s-title">内容介绍</div>
        <div class="p-desc">{{videoDesc}}</div>
      </div>
      <div class="videoList">
        <mySwiper @changeVideo="changeVideo" :nowWidth="screenWidth" />
      </div>
      <!-- <div @click="changeVideo">点击</div> -->
    </div>
    <footer class="footer">
      <div class="footer-box">
        <span class="protocol-item" @click="toProtocolPage(1)">注册协议</span>
        <span class="protocol-item" @click="toProtocolPage(2)">隐私协议</span>
        <span class="protocol-item" @click="toProtocolPage(3)">侵权投诉指引</span>
      </div>
      <div class="footer-info">
        <div>粤ICP备20000709号 | Copyright 2019-2020 佛山抖工科技有限公司</div>
        <!-- <a href="http://beian.miit.gov.cn" target="_blank"> 工信部 | </a> -->
        <div>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060502001916"
            title="抖工科技有限公司"
          >
            <i class="police"></i> 粤公网安备 44060502001916号
          </a>
        </div>
        <div>地址：佛山市南海区桂城街道桂澜北路28号南海万达广场南1栋2509室</div>
        <div style="margin-top: 5px;color: #515a6e;font-size: 1rem;">服务热线：400-640-6898</div>
      </div>
    </footer>
  </div>
</template>

<script>
import mySwiper from './mySwiper.vue'
export default {
  components: {
    mySwiper
  },
  data() {
    return {
      videoImg: [
        '../../assets/images/fzpt_img.png',
        '../../assets/images/videoImg1.png',
        '../../assets/images/videoImg2.png',
        '../../assets/images/videoImg3.png',
        '../../assets/images/videoImg4.png',
        '../../assets/images/videoImg5.png',
        '../../assets/images/videoImg6.png',
      ],
      vImgIndex: 0,
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: 'http://1257439021.vod2.myqcloud.com/d0d2cb24vodcq1257439021/52ab36f85285890817073869940/QC9Yu5bpDLcA.mp4'// url地址
          }
        ],
        hls: true,
        poster: require("../../assets/images/fzpt_img.png"), // 你的封面地址
        width: document.documentElement.clientWidth, // 播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      },
      videoTitle: '抖工安教应用案例介绍',
      videoDesc: `《中华人民共和国安全生产法》中第二十五条要求：“未经安全生产教育和培训合格的从业人员，不得上岗作业”。
抖工安教，结合生物特征识别等先进技术只需手机简单操作，就能随时随地完成实名安全培训并无缝对接考勤，优化工作流程。
安全教育培训从此更加合法合规、有效真实、文明且绿色。`,
      screenWidth: document.body.clientWidth,
      descFlag: true
    };
  },
  created() {},
  mounted() {
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        this.screenWidth = window.screenWidth;
      })();
    };
  },
  watch: {
    screenWidth(val) {
      if (!this.timer) {
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        // setTimeout(function() {
        //   // console.log(that.screenWidth);
        // }, 400);
      }
    },
  },
  destory() {},
  methods: {
    toHome(){
      this.$router.push({name: 'home'});
    },
    toVideo(){
      this.$router.push({name: 'video'});
    },
    toAbout(){
      this.$router.push({name: 'about'});
    },
    toPlatform(){
      const URL = 'http://doc.dougongapp.com:18080/en/docs/'
      window.open(URL, '_blank')
    },
    player() {
      return this.$refs.videoPlayer.player; //自定义播放
    },
    onPlayerPlay(e) {
      console.log(e);
    },
    onPlayerPause(e) {
      console.log(e);
    },
    changeVideo(val,idx){
      let player = this.$refs.videoPlayer.player
      console.log(val,idx)
      let index = idx || 0
      this.videoTitle = val.name;
      this.videoDesc = val.desc;
      switch(index){
        case 0: player.src('http://1257439021.vod2.myqcloud.com/d0d2cb24vodcq1257439021/52ab36f85285890817073869940/QC9Yu5bpDLcA.mp4');player.poster(require("../../assets/images/fzpt_img.png"));break;
        case 1: player.src('http://1257439021.vod2.myqcloud.com/d0d2cb24vodcq1257439021/8c8ef56a5285890817074008319/1iD1h2SsJxwA.mp4');player.poster(require("../../assets/images/videoImg1.png"));break;
        case 2: player.src('http://1257439021.vod2.myqcloud.com/d0d2cb24vodcq1257439021/54b38e725285890817073921693/UCfimfGJwqcA.mp4');player.poster(require("../../assets/images/videoImg2.png"));break;
        case 3: player.src('http://1257439021.vod2.myqcloud.com/d0d2cb24vodcq1257439021/5262c3715285890817073829505/lIc2ByUy6A0A.mp4');player.poster(require("../../assets/images/videoImg3.png"));break;
        case 4: player.src('http://1257439021.vod2.myqcloud.com/d0d2cb24vodcq1257439021/55005b605285890817073969848/GLuTe4rBrRwA.mp4');player.poster(require("../../assets/images/videoImg4.png"));break;
        case 5: player.src('http://1257439021.vod2.myqcloud.com/d0d2cb24vodcq1257439021/52609ecd5285890817073825811/2VAWB8a9uiAA.mp4');player.poster(require("../../assets/images/videoImg5.png"));break;
        case 6: player.src('http://1257439021.vod2.myqcloud.com/d0d2cb24vodcq1257439021/54a433315285890817073916643/Hwqy5oCJ4A0A.mp4');player.poster(require("../../assets/images/videoImg6.png"));break;
        default: player.src('http://1257439021.vod2.myqcloud.com/d0d2cb24vodcq1257439021/8c8ef56a5285890817074008319/1iD1h2SsJxwA.mp4');
      }
    },
    changeIntroduction(){
      this.descFlag = !this.descFlag
    }
  }
};
</script>
<style scoped lang="less">
.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    height: 64px;
    .p-head {
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .p-head-logo {
        width: 200px;
        text-align: center;
      }
    }
    .p-head-home {
      position: absolute;
      right: 3vw;
      top: 22px;
      color: #333;
    }
  }
  .head {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1.875em 10% 0 10%;
    align-items: flex-start;
    height: 80px;
    background: #f2f2f2;
    z-index: 30;
    .logobox {
      width: 120px;
      // margin-left: 50px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .navbox {
      display: flex;
      color: #434343;
      font-size: 16px;
      .navitem-on {
        color: #00c9ff;
      }
      .navitem {
        cursor: pointer;
        text-align: center;
        width: 100px;
      }
    }
  }
  .main {
    // min-height: 100vh;
    max-width: 1280px;
    // min-width: 1200px;
    padding: 0 10px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .watermark{
      z-index: 1;
      position: fixed;
      width: 50%;
      bottom: -10%;
      right: -10%;
      img{
        width: 100%;
        height: auto;
      }
    }
    .video-title{
      width: 100%;
      font-size: 22px;
      color: #0575e6;
      margin: 20px auto;
      padding-left: 20px;
      // font-family:HiraginoSansGB-W6,HiraginoSansGB;
    }
    .videoBox {
      width: 100%;
      height: auto;
      z-index: 10;
      background: #000;
      display: flex;
      margin-top: 10vh;
      align-items: center;
      position: relative;
      overflow: hidden;
      .open-btn{
        height: 45px;
        width: 12px;
        position: absolute;
        top: 50%;
        right: 0;
        cursor: pointer;
        z-index: 99;
        transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        img{
          width: 100%;
          height: auto;
        }
      }
      .video-player{
        flex: 1;
      }
      .vi-box{
        position: absolute;
        width: 20%;
        height: 100%;
        right: 0;
      }
      .video-introduction{
        background: #1f1f1f;
        width: 100%;
        height: 100%;
        padding: .5em;
        position: relative;
        .vi-title{
          color: #fff;
          font-size: 16px;
          border-bottom: 1px solid #282828;
          padding: 12px 0;
        }
        .small-title{
          color: #ccc;
          font-size: 14px;
          margin-top: 12px;
          margin-bottom: 6px;
        }
        .vi-desc{
          color: #999;
          font-size: 14px;
          max-height: 250px;
          overflow: hidden;
        }
        .close-btn{
          height: 45px;
          width: 12px;
          position: absolute;
          top: 50%;
          left: 0;
          cursor: pointer;
          img{
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .p-introduction{
      width: 100%;
        background: #1f1f1f;
        z-index: 11;
        padding: .5em;
      .p-title{
          color: #fff;
          font-size: 16px;
          border-bottom: 1px solid #282828;
          padding: 12px 0;
      }
      .p-s-title{
          color: #ccc;
          font-size: 14px;
          margin-top: 12px;
          margin-bottom: 6px;
      }
      .p-desc{
          color: #999;
          font-size: 14px;
      }
    }
    .videoList {
      width: 100%;
      margin-top: 25px;
      z-index: 10;
    }
  }
  .footer{
    width:100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 18px;
    margin-top: 68px;
    .footer-box{
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      .protocol-item{
        font-size:1rem;
        cursor:pointer;
        margin: 0 15px;
        width: 150px;
        text-align: center;
      }
    }
    .footer-info{
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      font-size: 12px;
      i{
        
        display:inline-block;
        vertical-align: middle;
        margin-top: -3px;
        margin-left: 5px;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }
      .police{
        background-image:url('../../assets/images/guohui.png');
      }
      a{
        color: #999;
      }
    }
}
}
</style>
